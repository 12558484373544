<template>
	<div>
		<RouterView />
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'App',
});
</script>
